@font-face {
  font-family: 'Roboto Medium';
  src: url('../fonts/Roboto-Medium.eot');
  src: url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto-Medium.woff2') format('woff2'),
  url('../fonts/Roboto-Medium.woff') format('woff'),
  url('../fonts/Roboto-Medium.ttf') format('truetype'),
  url('../fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Light';
  src: url('../fonts/Roboto-Light.eot');
  src: url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto-Light.woff2') format('woff2'),
  url('../fonts/Roboto-Light.woff') format('woff'),
  url('../fonts/Roboto-Light.ttf') format('truetype'),
  url('../fonts/Roboto-Light.svg#Roboto-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Reguler';
  src: url('../fonts/Roboto-Regular.eot');
  src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto-Regular.woff2') format('woff2'),
  url('../fonts/Roboto-Regular.woff') format('woff'),
  url('../fonts/Roboto-Regular.ttf') format('truetype'),
  url('../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gibson';
  src: url('../fonts/Gibson.eot');
  src: url('../fonts/Gibson.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gibson.woff2') format('woff2'),
  url('../fonts/Gibson.woff') format('woff'),
  url('../fonts/Gibson.ttf') format('truetype'),
  url('../fonts/Gibson.svg#Gibson') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gibson Semibold';
  src: url('../fonts/Gibson-SemiBold.eot');
  src: url('../fonts/Gibson-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gibson-SemiBold.woff2') format('woff2'),
  url('../fonts/Gibson-SemiBold.woff') format('woff'),
  url('../fonts/Gibson-SemiBold.ttf') format('truetype'),
  url('../fonts/Gibson-SemiBold.svg#Gibson-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gibson Light';
  src: url('../fonts/Gibson-Light.eot');
  src: url('../fonts/Gibson-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gibson-Light.woff2') format('woff2'),
  url('../fonts/Gibson-Light.woff') format('woff'),
  url('../fonts/Gibson-Light.ttf') format('truetype'),
  url('../fonts/Gibson-Light.svg#Gibson-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-SemiBold.eot');
  src: url('../fonts/SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
  url('../fonts/SourceSansPro-SemiBold.woff') format('woff'),
  url('../fonts/SourceSansPro-SemiBold.ttf') format('truetype'),
  url('../fonts/SourceSansPro-SemiBold.svg#SourceSansPro-SemiBold') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Clear Sans';
  src: url('../fonts/ClearSans.eot');
  src: url('../fonts/ClearSans.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ClearSans.woff2') format('woff2'),
  url('../fonts/ClearSans.woff') format('woff'),
  url('../fonts/ClearSans.ttf') format('truetype'),
  url('../fonts/ClearSans.svg#ClearSans') format('svg');
  font-weight: normal;
  font-style: normal;
}